:root{
  --primary: #efb6b2;
  --secondary: #777777;
  --error: #ff4a4a;
  --warning: #5252e4;
}


/* base styles & title */
body{
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--secondary);
  background-color: rgb(233, 228, 167);
  /*background: url(images/emsi_bg-min-25.jpg) no-repeat;*/
}

@media only screen and (max-width: 768px) {
  .App {
    margin-top: 10px;
  }

  h1 {
    font-size: 1.0rem;
  }

  h2 {
    font-size: 1.5rem;
  }
  .title .topRow {
    height: 10px;
    background-color: white;
    border-radius: 12px;
  
  }
  .title .logo {
    background: url(images/emsi_logo_50.jpg) no-repeat;
    background-color: #0f5b69;
    height: 66px;
  }
}

@media only screen and (min-width: 768px) {
  body{
    background: url(images/emsi_bg-min-25.jpg) no-repeat;
  }  
  .App {
    margin-top: 15px;
  }
  h1 {
    font-size: 1.2rem;

  }

  h2 {
    font-size: 2.6rem;
  }

  .title .topRow {
    height: 25px;
    background-color: white;
    border-radius: 12px;
  
  }

  .title .logo {
    background: url(images/emsi_logo.jpg) no-repeat;
    background-color: #0f5b69;
    height: 132px;
  }



}

.App{
  max-width: 960px;
  margin: 0 auto;
  background-color: white;
  border-radius: 12px 12px 0px 0px;
}

.title .bottomRow {
  height: 20px;
  background-color: #cd8e14;

}

.title .message {
  margin-left: 20px;
  margin-right: 20px;
}

.title h1{
  color: var(--primary);
  letter-spacing: 2px;
  font-weight: normal;
}
.title h2, .title p{
  text-align: center;
}
.title h2{
  margin-top: 30px;
}

.message2 h2 {
  text-align: center;
  color: var(--warning);
}

.footer {
  margin-top: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 5px;
  text-align: right;
  color: #e0e0dd;
  font-size: 0.5rem;
  background-color: #0f5b69;
}

.versePicker {
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
}

.versePicker .panel {
  margin-top: 10px;
  margin-bottom: 40px;
}

.versePicker button {
  background-color:#f1bb4e;
  display:inline-block;
  padding:0.8em 1.2em;
  margin:0 0.1em 0.1em 0;
  border:0.16em solid rgba(255,255,255,0);
  border-radius:2em;
  box-sizing: border-box;
  text-decoration:none;
  font-family:'Roboto',sans-serif;
  font-size: 16px;
  color: var(--secondary);
  text-shadow: 0 0.04em 0.04em rgba(0,0,0,0.35);
  text-align:center;
  transition: all 0.2s;
}
.versePicker button:hover {
  border-color: var(--secondary)

}
.versePicker button:focus {
  outline: none;
  border-color: var(--secondary)

}

.versePicker button:disabled {
    background-color: #888888;
    color: #eeeeee;
}

.versePicker .verse {
  background-color: #0f5b69;
  border-style: solid;
  border-width: 1px 1px 1px 0px;
  border-color: black;
  border-radius: 2px;
  color: #e0e0dd;
  padding: 10px 20px 10px 20px;
  text-align: left;
  font-size: 20px;
}

.versePicker .verse .reference {
  color: #E1731A;
  padding-top: 5px;
  text-align: right;
  font-size: 14px;
  font-style: italic;
}

.versePicker .verseCanvas {
  display: none;
}

.versePicker #min_logo {
  display: none;
}

.avoidClicks {
  pointer-events: none;
}